<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="编号" prop="bianHao" >
                <el-input v-model="form.bianHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="卡号" prop="kaHao" >
                <el-input v-model="form.kaHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="姓名" prop="xingMing" >
                <el-input v-model="form.xingMing" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="操作人" prop="caoZuoRen" >
                <el-input v-model="form.caoZuoRen" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="解冻时间" prop="jieDongSJ">
                <el-date-picker v-model="form.jieDongSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/hygl/JieDongJiLu";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                bianHao: [], // 编号
                kaHao: [], // 卡号
                xingMing: [], // 姓名
                caoZuoRen: [], // 操作人
                jieDongSJ: [], // 解冻时间
            }
            this.titlePrefix = '解冻记录';
            this.defaultForm = {
                id: null,
                bianHao: "", // 编号
                kaHao: "", // 卡号
                xingMing: "", // 姓名
                caoZuoRen: "", // 操作人
                jieDongSJ: "", // 解冻时间
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        }
    }
</script>

<style scoped>

</style>